<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <product :id="id"></product>
            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Product from "../../../components/app/product/product";

    export default {
        name: 'Product_view',
        data: function(){
            return {
                pageName: 'Product',
                segments: [
                    { link: true, text: 'Products', routeName: 'App.Products' },
                    { link: false, text: 'Product' },
                ],
                id: 0
            }
        },
        props: {},
        components: {
            Product,
            BreadCrumb
        },
        computed: {

        },
        methods:{

        },
        created() {
            this.id = this.$route.params.id;
        }
    }
</script>

<style scoped>

</style>
